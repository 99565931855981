@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/300-Avenir-Light-07.woff) format('woff');
    font-weight: 300;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/400-AvenirLTStd-Book.woff) format('woff');
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/600-Avenir-Medium-09.woff) format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/700-Avenir-Heavy-05.woff) format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'sd-avenir';
    src: url(./fonts/Avenir/800-AvenirLTStd-Black.woff) format('woff');
    font-weight: 800;
}

body {
    font-family: sd-avenir, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
