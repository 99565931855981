:root {
    --theme-color-bright: #2cab83;
    --theme-color-dark: #586980;
    --sd-base-gray: #283237;
    --sd-base-gray-25: #f8f9fd;
    --sd-base-gray-50: #edeff1;
    --sd-base-gray-700: #596d79;
    --sd-base-gray-800: #495963;
    --sd-base-gray-900: #283237;
    --sd-base-gray-400: #94a3ad;
    --sd-base-gray-300: #b3bec4;
    --sd-base-gray-500: #7d8f9b;
    --sd-neutral-200: #F7F9FA;
    --sd-neutral-300: #EFEFEF;
    --sd-neutral-400: #D3D3D3;
    --sd-neutral-500: #7C7C7C;
    --sd-neutral-600: #4A4A4A;
    --sd-neutral-700: #242424;
    --sd-neutral-800: #101010;
    --sd-primary-100: #17C289;
}

.stateLines {
    gap: 6px;
    background-color: white;
    height: 4px;
    margin: 0px 4px;
}

.stateLines div {
    width: 100%;
    border-radius: 2px;
}

.greenLine {
    background-color: var(--sd-primary-100);
}

.ashLine {
    background-color: var(--sd-neutral-400);
}

.banner-div {
    padding: 12px;
}

.sliding-menu {
    --underline-width: 78px;
    --underline-offset-x: 0;
    height: 3px;
    background-color: var(--sd-primary-100);
    width: var(--underline-width);
    transform: translateX(var(--underline-offset-x));
    transition: all 0.3s ease-in-out;
}

.sliding-menu-text {
    cursor: pointer;
    font-weight: 600;
    color: var(--sd-neutral-500);
}

.sliding-menu-selected {
    color: var(--sd-primary-100-2) !important;
}