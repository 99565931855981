#creditCardAddFrom .stripeCard {
    margin-top: 10px;
    color: #31325F;
    line-height: 40px;
    font-weight: 300;
    font-size: 15px;
}

#creditCardAddFrom .stripeCard input::placeholder {
    color: var(--sd-base-gray-25) !important;
}

#creditCardAddFrom form {
    width: 100%;
    margin: 10px 0px 20px 0;
}

#creditCardAddFrom .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
}

#creditCardAddFrom label {
    position: relative;
    color: #8898AA;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}

#creditCardAddFrom .group label:not(:last-child) {
    border-bottom: 1px solid #F0F5FA;
}

#creditCardAddFrom label > span {
    width: 45px;
    text-align: left;
    margin-right: 25px;
}

#creditCardAddFrom .field {
    background: transparent;
    font-weight: 300;
    border: 0;
    color: var(--sd-neutral-400);
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
}

#creditCardAddFrom .field::-webkit-input-placeholder {
    color: var(--sd-neutral-400) !important;
}

#creditCardAddFrom .field::-moz-placeholder {
    color: var(--sd-neutral-400) !important;
}

#creditCardAddFrom .field::placeholder {
    color: var(--sd-neutral-400) !important;
}

#creditCardAddFrom button {
    float: left;
    display: block;
    background: var(--sd-primary-100);
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    height: 40px;
    line-height: 0px;
    outline: none;
}

#creditCardAddFrom button:focus {
    background: #555ABF;
}

#creditCardAddFrom button:active {
    background: #43458B;
}

#creditCardAddFrom .outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 20px;
    text-align: center;
}

#creditCardAddFrom .success, .error {
    display: none;
    font-size: 13px;
}

#creditCardAddFrom .success.visible, .error.visible {
    display: inline;
}

#creditCardAddFrom .error {
    color: #E4584C;
}

#creditCardAddFrom .success {
    color: #666EE8;
}

#creditCardAddFrom .success .token {
    font-weight: 500;
    font-size: 13px;
}

