:root {
    --theme-color-bright: #2cab83;
    --theme-color-dark: #586980;
    --sd-base-gray: #283237;
    --sd-base-gray-25: #f8f9fd;
    --sd-base-gray-50: #edeff1;
    --sd-base-gray-700: #596d79;
    --sd-base-gray-800: #495963;
    --sd-base-gray-900: #283237;
    --sd-base-gray-400: #94a3ad;
    --sd-base-gray-300: #b3bec4;
    --sd-base-gray-500: #7d8f9b;
}

input {
    line-height: 1.8 !important;
}

input::placeholder {
    font-size: 1rem;
}

.enterprisePortalHeader {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-left: 15px;
}

.content-body {
    margin: 50px 24px 50px 55px;
}

.shipdayLink {
    color: var(--theme-color-bright);
    text-decoration: none;
}

.shipdayLink:hover {
    color: var(--theme-color-bright);
}

.partnerPortalTitle {
    font-size: 11px;
    line-height: 0px;
    margin-left: calc(5rem + .5vw);
}

.partnerEmail {
    color: var(--sd-base-gray-700);
    font-size: 14px;
}

.partnerName {
    color: var(--sd-base-gray-900);
    font-size: 20px;
}

.colorGray {
    color: var(--sd-base-gray-700);
}

.infoCard {
    min-width: 200px;
    max-width: 250px;
}

.infoCard .card-title {
    font-size: 1.1rem;
    white-space: nowrap;
}

.customDateInput {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 450px;
}

.customDateInputBox {
    width: 160px;
    max-width: 160px;
}

.btn-query {
    color: var(--sd-base-gray-700) !important;
    background: var(--sd-base-gray-50) !important;
    border-left: 1px solid #d1d8db !important;
}

.btn-group > :first-child {
    border-left: none !important;
}

.btn-active {
    color: var(--sd-base-gray-900) !important;
    background: white !important;
    box-shadow: none;
}

.bi-calendar-date {
    pointer-events: none;
}

.side-navbar {
    color: var(--sd-neutral-600);
    font-weight: 600;
    width: 250px;
    height: 100%;
    position: fixed;
    margin-left: -300px;
    background: #ffffff;
    transition: 0.5s;
    word-break: break-word;
    border-right: 1px solid var(--sd-neutral-300);
    z-index: 1;
    padding-top: 80px;
}

.nav-link {
    cursor: pointer;
    border-radius: 8px;
    padding: 12px;
    margin: 1px 8px;
}

.nav-link:hover {
    background: var(--sd-neutral-200);
}

.nav-selected {
    color: #17C289;
}

.mainContainer {
    transition: 0.4s;
    min-width: 400px;
    height: 100vh;
}

.activeNav {
    margin-left: 0;
}

.activeCont {
    margin-left: 250px !important;
    width: calc(100% - 250px) !important;
}

.activeCont .content-body {
    margin-left: 24px;
}

.shadow-sm {
    box-shadow: 0 3px 9px #0000001d !important;
}

.infoBtn {
    color: var(--sd-base-gray-300);
}

.infoBtn:hover {
    color: var(--theme-color-bright);
}

table tbody tr:hover {
    background-color: var(--sd-base-gray-25);
}

#creditCardAddModal {

}

/*#creditCardAddModal .modal-header {*/
/*    flex-direction: column-reverse;*/
/*    padding-bottom: 0;*/
/*    border-bottom: none;*/
/*}*/

.modal-header {
    padding: 0 !important;
}

#creditCardAddModal .btn-close {
    box-shadow: none;
    background-color: white;
}

.invoiceLink {
    color: var(--sd-base-gray-500);
}

.invoiceLink:hover {
    color: var(--theme-color-bright)
}

.passUpdateBody .form-control {
    font-size: 15px;
}

.passMismatchWarning {
    font-size: 15px;
}

.passUpdateBtn {
    background-color: var(--theme-color-bright);
    color: white;
}

.passUpdateBtn:hover {
    background-color: #32c293;
    color: white;
}

.accordion-button {
    position: initial !important;
}

@media only screen and (max-width: 768px) {
    body {
        word-wrap: break-word;
    }

    .passUpdateBody [class*="col-"] {
        min-width: 150px;
    }

    .min-width-md-parent {
        padding-right: 1.5rem;
    }
}

@media only screen and (max-width: 512px) {
    .mainContainer {
        height: 100%;
    }
}

.text-btn {
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.text-btn:hover {
    font-weight: 600;
}

.bankInfoInput {
    max-width: 220px;
    min-width: 140px;
}

.bankInfoInputLabel {
    min-width: fit-content;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: white;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media screen and (max-width: 658px) {
    .signup-form {
        padding: 0 30px !important;
    }
}

.signup-form {
    padding: 0 25%;
}

.bi-clipboard:hover {
    color: green;
}

.bi-check2 {
    color: green;
}

.white-color {
    color: white !important;
}

.modal-md {
    max-width: 450px !important;
}

.white-color:hover {
    color: white !important;
}

a, .btn-link {
    color: #2cab83 !important;
}

.btn-link:focus {
    box-shadow: none;
}

.analytics-chart {
    max-height: 400px;
    height: 350px;
    background: #FFFF;
    margin: -6px -13px 30px
}

.analytics-time-selector {
    margin: 25px 15px 10px;
    float: right;
}

.analytics-table-type-selector {
    margin: 0px;
    display: flex;
    justify-content: space-between;
}

.analytics-table-type-selector .sd-btn-bordered-group {
    margin-left: 0px !important;
}

.time-selector-btns .btn-group .btn:focus {
    outline: none;
    box-shadow: none;
}

.time-selector-mobile-view {
    font-size: 12px;
    padding: 5px;
}

.analytics-header-text {
    color: #596D79
}

.analytics-hero-text {
    color: #495963;
}

.analytics-cards {
    cursor: pointer;
}

.analytics-cards .card-body {
    min-width: 260px;
}

.analytics-cards.card-selected {
    border: 1px solid var(--sd-primary-100) !important;
}

.analytics-parent {
    transition: all 0.3s ease-in-out;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.7;
}

.multi-dropdown-section {
    min-width: 450px;
    z-index: 100;
    border-radius: 8px;
    max-height: 35vh;
    overflow-y: auto;
}

.cur-pointer {
    cursor: pointer;
}

.multi-dropdown {
    min-width: 450px
}

.multi-dropdown-section input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    content: 'x';
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
    cursor: pointer;
    background-size: 10px 10px;
}

.multi-dropdown-section input[type=search] {
    -webkit-appearance: none;
    appearance: none;
}

*:focus {
    outline: none !important;
}


.table-v2 tbody {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 18px;
    color: var(--sd-neutral-600) !important;
}

.table-v2 .border-top {
    border-top: 1px solid var(--sd-neutral-300) !important;
}

.table-v2 thead th {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 15px !important;
    color: var(--sd-neutral-800) !important;
}

.table-v2 > :not(caption) > * > * {
    padding: 15px;
}

/*General Changes*/
.partner-welcome {
    color: var(--sd-neutral-800);
    font-size: 24px;
    font-weight: 600;
}

/*Card Changes*/
.card-container {
    gap: 18px;
}

.card {
    border: 1px solid var(--sd-neutral-300) !important;
}

.card-body {
    padding: 12px 16px !important;
    gap: 8px !important;
}

.card-top {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: var(--sd-neutral-600) !important;
}

.card-hero {
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    color: var(--sd-neutral-800) !important;
}

/*Hambarger icon*/

.menu-icon-wrapper {
    position: absolute;
    left: 20px;
    z-index: 3;
    margin-top: -24px;
    margin-right: calc(100% - 110vw);
    padding-top: 24px;
    padding-bottom: 15px;
    /* border-bottom: 1px solid var(--sd-neutral-300); */
    width: calc(100% - 30px);
    background-color: white;
}

.menuIcon {
    width: 2rem;
    height: 1.6rem;
    padding: .3125rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid var(--sd-neutral-300);
}

.menuIcon:hover {
    box-shadow: 1px 1px 2px 0px var(--sd-neutral-400);
    transition: all .2s ease-in-out;
}

.navbar-toggle-icon {
    position: relative;
    height: .125rem;
    width: 1.16rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: block;
    top: -0.3125rem;
    padding-right: 0
}

.navbar-toggle-icon .toggle-line {
    display: block;
    height: 100%;
    border-radius: .25rem;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: var(--sd-neutral-600)
}

.navbar-toggle-icon:after, .navbar-toggle-icon:before {
    content: "";
    position: absolute;
    height: .125rem;
    background-color: var(--sd-neutral-600);
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-radius: .25rem;
    width: 100%
}

.navbar-toggle-icon:before {
    top: .3125rem
}

.navbar-toggle-icon:after {
    top: .625rem
}

.navbar-toggle-icon.collapsed {
    width: 100%;
}

.navbar-toggle-icon.collapsed:before {
    width: 50%;
}

.navbar-toggle-icon.collapsed:after {
    width: 75%;
}

.border-b-sm {
    border-bottom: 1px solid var(--sd-neutral-300);
}


.copy-text-btn {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: var(--sd-primary-100);
    cursor: pointer;
}

.info-container {
    padding: 6px 24px;
    border: 1px solid var(--sd-neutral-300);
    border-radius: 8px;
    max-width: 1200px;
    width: 100%;
}

.info-header {
    font-size: 15px;
    color: var(--sd-neutral-800);
    line-height: 20px;
    font-weight: 600;
    width: 30%;
}

.info-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--sd-neutral-600);
    font-weight: 400;
    width: 50%;
}

.info-btn {
    width: 20%;
}

.single-info {
    padding: 24px 0px;
    display: flex;
    align-items: center;
}

.single-info:not(:last-child) {
    border-bottom: 1px solid var(--sd-neutral-300);
}

.sd-shadow {
    border: 1px solid var(--sd-neutral-300);
    border-radius: 8px;
}

.profile-div {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.sub-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--sd-neutral-600);
}

.comp-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--sd-neutral-700);
    line-height: 40px;
}

.success-btn {
    background-color: var(--sd-primary-100);
    color: white;
    float: none !important;
}

.sd-btn-group {
    border: 1px solid var(--sd-neutral-300);
    border-radius: 4px;
}

.sd-btn-group.border-deep {
    border: 1px solid var(--sd-neutral-400);
}

.sd-border-start {
    border-left: 1px solid var(--sd-neutral-300);
}

.sd-border-end {
    border-left: 1px solid var(--sd-neutral-300);
}

.sd-time-btn {
    font-size: 14px;
    font-weight: 500;
    background-color: var(--sd-neutral-200);
}

.sd-time-btn:hover {
    background-color: var(--sd-neutral-300);
}

.sd-selector {
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding-left: 20px;
    border: 1px solid var(--sd-neutral-300) !important;
}

.sd-selector-text {
    font-size: 14px;
    font-weight: 500;
}

.tableBtnWithBorder {
    height: 28px;
    width: 28px;
}

.disabledLoading {
    color: var(--sd-neutral-500);
    pointer-events: none;
}

.tableBtnWithBorder, .downloadBtn {
    border: 1px solid var(--sd-neutral-300);
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.downloadBtn:hover, .tableBtnWithBorder:hover {
    border: 1px solid var(--sd-neutral-400);
}

.bank-info-container {
    padding: 10px 30px;
    border-top: 1px solid var(--sd-neutral-300);
    width: 100%;
    justify-content: space-between;
    text-align: center;
    flex-direction: row;
    align-items: center;
}

.bank-info-available {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}

.bank-icon-info {
    color: #17C289;
    padding: 10px;
    background-color: var(--sd-neutral-200);
    border-radius: 5px;
}

.bank-name {
    color: var(--sd-neutral-800);
    font-weight: 600;
    font-size: 18px;
}

.bank-number {
    color: var(--sd-neutral-500);
    font-weight: 400;
    font-size: 15px;
}

.bank-verify-btn {
    background-color: #008CBA;
    width: 110px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.bank-no-info {
    color: var(--sd-neutral-800);
    font-size: 18px;
    line-height: 24px;
    width: 65%;
}

.sub-text {
    color: var(--sd-neutral-500, #4A4A4A);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
}

.bank-info-add-btn {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
}

.bank-info-add-btn:hover {
    background-color: var(--sd-primary-100);
    color: white;
}

.modal-dialog {
    max-width: 600px;
    margin: 30px auto;
}

/*Modal CSS*/
.modal-dialog-centered {
    padding-bottom: 15% !important;
}

.sd-close {
    background-color: var(--sd-neutral-300);
    padding: 13px 14px !important;
    border-radius: 4px;
    color: var(--sd-neutral-800);
    display: flex;
    border: none;
    align-items: center;
    margin-left: auto;
}

.sd-close:hover {
    background-color: var(--sd-neutral-400);
}

.modal-title {
    font-size: 24px !important;
    font-weight: 600;
    line-height: 40px;
    color: var(--sd-neutral-800)
}

.modal-header {
    padding: 0 !important;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-content {
    padding: 24px !important;
    border-radius: 8px !important;
}

.modal-body {
    padding: 0 !important;
}

.bank-input {
    text-align: left;
    margin-top: 15px;
}

.bank-input .form-label {
    color: var(--sd-neutral-800);
    font-size: 15px;
    line-height: 20px;
}

.bank-input .form-control {
    font-size: 15px;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid var(--sd-neutral-400, #D3D3D3);
    box-shadow: 0 0 0 0 rgba(23, 194, 137, 0.70);
}

.bank-input .form-control::placeholder {
    color: var(--sd-neutral-400);
}

.submit-btn {
    border-radius: 4px;
    background: var(--sd-primary-100, #17C289);
    padding: 8px 12px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    border: none;
    float: right;
    margin-top: 20px;
}

.cancel-btn {
    border-radius: 4px;
    background-color: var(--sd-neutral-300);
    padding: 7px 12px;
    font-size: 15px;
    font-weight: 600;
    color: var(--sd-neutral-600);
    margin-top: 20px;
    border: 1px solid var(--sd-neutral-400);
    float: right;
    margin-left: 10px;
}

.bank-mandate-text {
    padding-top: 20px;
    text-align: left;
    color: var(--sd-neutral-500);
    font-weight: 400;
}

.billing-info-update-acc {
    padding: 6px 24px;
    border-radius: 10px !important;
    outline: none !important;
}

.accordion-button {
    outline: none !important;
}

.accordion-button:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.credit-card-info-holder {
    justify-content: space-between;
    text-align: center;
    flex-direction: row;
}


.bank-details-texts {
    margin-left: 36.3%;
}

.no-wrap {
    white-space: nowrap;
}


.email-badge {
    display: inline-block;
    padding: 5px 15px;
    background-color: lightgray; 
    color: black; 
    border-radius: 50px; 
    font-size: 14px; 
    text-align: center;
    font-weight: bold; 
}

.leading-4_5 {
    line-height: 18px;
}

.max-w-300 {
    max-width: 300px;
}

.email-inputs {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    min-height: 50px;
}

.email-inputs:focus-within {
    border-color: #80bdff; /* Bootstrap focus border color */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.email-inputs input {
    border: none;
    flex-grow: 1;
}

.opacity-50 {
    opacity: 0.5;
}

.feedback-with-star {
    display: flex;
    align-items: center;
    line-height: 13px;
}

.feedback-with-star svg {
    width: 11px;
    color: orange;
}

.feedback-number {
    margin-right: 3px;
}