.loginBody {
    height: 100vh;
    width: 100vw;
    font-family: "avenir", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: #767676 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 620px) {
    .loginBody {
        flex-direction: column;
    }
}

.login-form-section {
    background-color: var(--sd-neutral-200);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-form-wrapper {
    background-color: white;
    padding: 32px;
    border-radius: 8px;
    border: 1px solid var(--sd-neutral-300);
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.05);
    width: 60%;
    max-width: 500px;
    min-width: 360px;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.signinSection {
    background: white;
    min-height: 560px;
}

.signinContainer {
    width: 40% !important;
    min-width: 320px;
}

.customInput {
    border-radius: 0.15rem !important;
    padding: 0.5rem 0.75rem !important;
    border: 1px solid #d1d8db !important;
}

.customInput:not(:placeholder-shown) {
    border: 1px solid var(--sd-base-gray-700) !important;
}

.customInput:hover {
    border: 1px solid #B3BEC4 !important;
}

.customInput:focus {
    border: 1px solid #2EB487 !important;
    box-shadow: none !important;
}

.customInput::-webkit-input-placeholder {
    color: #b3b3b3 !important;
    font-weight: 100 !important;
    font-size: 14px !important;
}

/* WebKit, Blink, Edge */
.customInput:-moz-placeholder {
    color: #c8c8c8 !important;
    font-weight: 100 !important;
    font-size: 12px !important;
}

/* Mozilla Firefox 4 to 18 */
.customInput::-moz-placeholder {
    color: #c8c8c8 !important;
    font-weight: 100 !important;
    font-size: 12px !important;
}

/* Mozilla Firefox 19+ */
.customInput:-ms-input-placeholder {
    color: #c8c8c8 !important;
    font-weight: 100 !important;
    font-size: 12px !important;
}

/* Internet Explorer 10-11 */
.customInput::-ms-input-placeholder {
    color: #c8c8c8 !important;
    font-weight: 100 !important;
    font-size: 12px !important;
}

/* Microsoft Edge */

.customInput::placeholder {
    color: #c8c8c8 !important;
    font-weight: 100 !important;
    font-size: 12px !important;
    opacity: 1 !important;
}

.forgotPass {
    color: #898989 !important;
    font-size: 12px !important;
    text-decoration: none !important;
}

.primaryBtn {
    background: var(--theme-color-bright) !important;
    color: white !important;
    border-radius: 2px !important;
    border: 1px solid var(--theme-color-bright) !important;
    margin: 30px 0px !important;
    padding: 0.65rem 0.75rem !important;
}

.primaryBtn:hover {
    background-color: var(--sd-primary-100) !important;
    color: white !important;
}

.policy {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--sd-neutral-600);
}

.policy a {
    text-decoration: none;
    color: var(--sd-base-gray);
}

.singinInputHeader {
    font-size: 14px;
    line-height: 15px;
    font-weight: 300;
}

.redirectLinks {
    text-decoration: none;
    color: var(--sd-base-gray-800);
}

.signinSection a:hover {
    color: var(--theme-color-bright);
}

.logoSection {
    background: linear-gradient(212.62deg, #17C289 0%, #0AE39B 100%);
    height: 100%;
    min-width: 320px;
    min-height: 560px;
}

.loginLogo {
    max-width: 250px;
    height: auto;
}

.wrong-pass-notification {
    position: absolute;
    bottom: 30px
}

.pb-logo {
    padding-bottom: 100px !important;
}

@media (max-width: 620px) {
    .logoSection {
        height: 200px !important;
        min-height: 200px !important;
    }

    .pb-logo {
        padding-bottom: 0px !important;
    }
}

.signup-forms-back {
    background-color: var(--sd-neutral-200);
    min-height: calc(100vh - 60px);
    height: fit-content;
    padding-top: 44px;
    display: flex;
    justify-content: center;
}

.signup-forms-wrapper {
    background-color: #ffffff;
    border: 1px solid var(--sd-neutral-300);
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 32px;
    height: fit-content;
    width: 45%;
    min-width: 500px;
    max-width: 540px;
}

@media (max-width: 550px)  {
    .signup-forms-wrapper {
        padding: 24px;
        width: 90vw;
        min-width: 340px;
        max-width: 95vw;
    }
}

.gap-8 {
    gap: 8px;
}

.login-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--sd-neutral-800);
}

.login-sub {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--sd-neutral-600);
}

.partner-bullets {
    gap: 16px;
    margin: 24px 0px;
}

.partner-bullets .bullet-text {
    font-size: 15px;
    font-weight: 600;
    color: var(--sd-neutral-800);
}

.login-btn {
    padding: 10px 16px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px !important;
    cursor: pointer;
}

.login-secondary-btn {
    background-color: var(--sd-neutral-200);
    color: var(--sd-neutral-800);
}

.login-primary-btn {
    background-color: var(--sd-primary-100);
    color: white;
    border: none
}

.login-primary-btn:disabled {
    pointer-events: none;
    opacity: 0.75;
}

.sd-form {
    gap: 20px;
}

.sd-input {
    margin-top: 4px;
    padding: 5px 16px;
    border: 1px solid var(--sd-neutral-400);
    border-radius: 4px;
    font-size: 15px;
    color: var(--sd-neutral-700);
}

.sd-label {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: var(--sd-neutral-800);
}

.sd-select {
    margin-top: 4px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid var(--sd-neutral-400);
}

.sd-input:focus, .sd-select:focus {
    border: 1px solid var(--sd-primary-100);
    box-shadow: none;
    outline: none;
}

.loggingIn-spinner {
    color: var(--sd-primary-100);
    height: calc(100vh - 60px);
    align-items: center;
}
