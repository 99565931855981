.pagination {
  margin: 1rem 0;
}

.page-link {
  padding: 0.5rem 1rem;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: all 0.2s ease;
}

.page-link:hover:not(:disabled) {
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item span.page-link {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}

.table-v2-wrapper .table-responsive {
    margin: 1rem 0;
}

.table-v2-wrapper .table.table-hover {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 18px;
    color: var(--sd-neutral-600) !important;
}

.table-v2-wrapper .table thead th {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 15px !important;
    color: var(--sd-neutral-800) !important;
    background-color: transparent;
    border-bottom: 1px solid var(--sd-neutral-300) !important;
}

.table-v2-wrapper .table > :not(caption) > * > * {
    padding: 15px;
}

.table-v2-wrapper .table tbody td {
    border-top: 1px solid var(--sd-neutral-300) !important;
    border-bottom: none;
}

.table-v2-wrapper .table tbody tr:last-child td {
    border-bottom: 1px solid var(--sd-neutral-300) !important;
}

.table-v2-wrapper .table-hover tbody tr:hover {
    background-color: var(--sd-base-gray-25);
}

.table-v2-wrapper .pagination {
    margin: 1rem 0;
}

.table-v2-wrapper .page-link {
    color: var(--theme-color-bright) !important;
    border: 1px solid var(--sd-neutral-300);
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
}

.table-v2-wrapper .page-link:hover:not(:disabled) {
    background-color: var(--sd-neutral-200);
    border-color: var(--sd-neutral-300);
}

.table-v2-wrapper .page-item.disabled .page-link {
    color: var(--sd-neutral-500) !important;
    background-color: var(--sd-neutral-200);
    border-color: var(--sd-neutral-300);
}

.table-v2-wrapper .page-item span.page-link {
    background-color: white;
    border: 1px solid var(--sd-neutral-300);
    color: var(--sd-neutral-800) !important;
}

@media (max-width: 768px) {
    .table-v2-wrapper .table thead th,
    .table-v2-wrapper .table tbody td {
        padding: 12px;
    }
    
    .table-v2-wrapper .page-link {
        padding: 6px 10px;
    }
}

.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.entries-info {
    color: var(--sd-neutral-600);
    font-size: 14px;
}

.table-v2-wrapper .page-item.active .page-link {
    background-color: var(--theme-color-bright);
    border-color: var(--theme-color-bright);
    color: white !important;
}
